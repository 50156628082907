const sliderSettings = {
    dots: true,
    infinite: false,
    autoplay: true,
    speed: 1600,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,

    responsive: [
      
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },
    ],
  };

export default sliderSettings;