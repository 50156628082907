const appsettings = {
  apiUrlDev: "https://localhost:44345",
  apiUrlLive: "https://betkittyapi.1cctech.net",
  useDevUrl: false,
  apiUrl: () => {
    if (appsettings.useDevUrl) {
      return appsettings.apiUrlDev;
    }
    return appsettings.apiUrlLive;
  },
  stripePriceId: "price_1IQGkgCJNKMX7NUqWxIIHezm",
  stripePublicKey:
    "pk_live_51IGnGbCJNKMX7NUqITE4indpj7V4xi7CUQN2iTUw63Y0sBCEWqfygI09n9gOe6ql5zeuDf5Rsa0pB98fWrKt32DE00dD9Fe7DL",
  stripeRedirectDomain: "https://betkitty.co.uk",
};

export default appsettings;

/*


    stripePriceId: 'price_1IO9P5CJNKMX7NUqzLduPG4f',
    stripePublicKey: 'pk_test_51IGnGbCJNKMX7NUqVdv0NGgHjajdKuJYhrT5YkyQIhUF8bwkQYTOZROQlKRmyiLme4EDrWPLCMMRxpbebBVkmp7W008Au9SraH',
    stripeRedirectDomain: 'http://localhost:3000'

    */
