import React, { useEffect, useState } from "react"
import swal from '@sweetalert/with-react'
import Axios from "axios";
import appsettings from "../appsettings";
import {  useHistory } from "react-router-dom";


export default function MakeYourSelectionsSwal() {
    const history = useHistory();

    // make a request and we only get a result for anything to show.  
    const [gameData, setGameData] = useState({});
    const [counter, setCounter] = useState(-1);


    useEffect(() => {
        async function getSelection() {
            try {
                const { data } = await Axios.get(
                    `${appsettings.apiUrl()}/api/CheckUnsubmittedGames`
                );
                setGameData(data);
                console.log(data);

                if (data.length > 0) {
                    setCounter(0);
                }

            } catch (e) {
            }
        }
        getSelection();
    }, []);


    useEffect(() => {
        console.log(counter);
        if (counter > -1 && counter < gameData.length) {
            swal(
                <div>
                    <h1 className="swal-h1">Make Your Predictions!</h1>
                </div>
            ).then((e) => { history.push("/make-selections/" + gameData[counter].code); })
        }
    }, [counter])



    return ("")
}