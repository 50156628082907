import React, { useEffect, useState } from "react"
import swal from '@sweetalert/with-react'
import Badge from "./BadgeAwardAssets/Badge"
import { badgeForRef } from "./BadgeAwardAssets/BadgeForRef";
import Axios from "axios";
import appsettings from "../appsettings";


export default function BadgeAwardSwal() {

    // make a request and we only get a result for anything to show.  
    const [badgeData, setBadgeData] = useState({});
    const [counter, setCounter] = useState(-1);

    useEffect(() => {
        async function getSelection() {
            try {
                const { data } = await Axios.get(
                    `${appsettings.apiUrl()}/api/NewBadgeAwards`
                );
                setBadgeData(data);
                console.log(data);

                if (data.length > 0) {
                    setCounter(0);
                }

            } catch (e) {
            }
        }
        getSelection();
    }, []);


    useEffect(() => {
        console.log(counter);
        if (counter > -1 && counter < badgeData.length) {
            swal(
                <div>
                    <h1 className="pagetitle">New Trophy!</h1>
                    <div>
                        <Badge icon={badgeForRef(badgeData[counter].badgeDefinition.ref)} complete={true} title={badgeData[counter].badgeDefinition.displayName}></Badge>
                    </div>
                </div>
            ).then(() => { setCounter(counter + 1) })
        }
    }, [counter])



    return ("")
}