import { useEffect, useState } from 'react';
import './Loading.css'

const Loading = () => {
    const [hung, setHung] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setHung(true)
        }, 10000);
        return () => clearTimeout(timer);
    }, []);

    if (hung)
        return null;

    return (
        <>
            <div className="loading">
                <div className='uil-ring-css' >
                    <div></div>
                </div>
            </div>

        </>

    )
}

export default Loading;