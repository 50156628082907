// import axaBadge from "../../components/images/Badges-3KAXAPartnership.png"
import React from 'react'
import {ReactComponent as RefBadgeKittyCaptain} from "./General/kitty-captain.svg"
import {ReactComponent as RefBadgeKittyGoldCaptain} from "./General/gold-captain.svg"
import {ReactComponent as RefBadgeKittyPlatinumCaptain} from "./General/platinum-captain.svg"
import {ReactComponent as RefBadgeBeatKitty} from "./General/i-beat-kitty.svg"
import {ReactComponent as RefBadgeFirstTimeKittyWinner} from "./General/first-time-kitty-winner.svg"
import {ReactComponent as RefBadge3TimesKittyWinner} from "./General/3-times-kitty-winner.svg"

// const Badge3K = <img src={axaBadge} alt="3K Badge" />;
const BadgeKittyCaptain = <><RefBadgeKittyCaptain /></>;
const BadgeKittyGoldCaptain = <><RefBadgeKittyGoldCaptain /></>;
const BadgeKittyPlatinumCaptain = <><RefBadgeKittyPlatinumCaptain /></>;
const BadgeBeatKitty = <><RefBadgeBeatKitty /></>;
const BadgeFirstTimeKittyWinner = <><RefBadgeFirstTimeKittyWinner /></>;
const Badge3TimesKittyWinner = <><RefBadge3TimesKittyWinner /></>;

const Badges = [ 
    { ref: "KittyCaptain", icon: BadgeKittyCaptain },
    { ref: "GoldCaptain", icon: BadgeKittyGoldCaptain },
    { ref: "PlatinumCaptain", icon: BadgeKittyPlatinumCaptain },
    { ref: "BeatKitty", icon: BadgeBeatKitty },
    { ref: "FirstTimeKittyWinner", icon: BadgeFirstTimeKittyWinner },
    { ref: "3timeskittywinner", icon: Badge3TimesKittyWinner },
]

const badgeForRef = ref => Badges.filter( b => b.ref === ref ).shift().icon;

export {
    BadgeKittyCaptain,
    Badges,
    badgeForRef
}