import React, { useEffect, useState } from "react";
import Axios from "axios";
import Badge from "../components/BadgeAwardAssets/Badge"
import appsettings from "../appsettings";
import { badgeForRef } from "../components/BadgeAwardAssets/BadgeForRef";
import LoadingClay from "../components/LoadingClay";
import ChallengeContainer from "../components/BadgeAwardAssets/ChallengeContainer";


function AllChallengesContainer() {
    const [UserChallenges, setUserChallenges] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function getSelection() {
            try {
                const { data } = await Axios.get(
                    `${appsettings.apiUrl()}/api/ListChallenges?game=footy`
                );
                console.log(data);
                if (data.length > 0) {
                    const renderableChallenges = data.map(badge => <Badge
                        title={badge.displayName}
                        key={badge.ref}
                        complete={badge.complete}
                        icon={badgeForRef(badge.ref)}
                    />
                    )
                    setUserChallenges(renderableChallenges);
                    console.log(renderableChallenges)
                }
                setIsLoading(false);

            } catch (e) {
                console.log(e);
            }
        }
        getSelection();
    }, []);

    return (
        <>
            { isLoading ?
                <ChallengeContainer>                    
                    <LoadingClay height="10rem" width="100%"></LoadingClay>
                    <LoadingClay height="10rem" width="100%"></LoadingClay>
                    <LoadingClay height="10rem" width="100%"></LoadingClay>
                    <LoadingClay height="10rem" width="100%"></LoadingClay>
                </ChallengeContainer>
                :
                <ChallengeContainer>
                    {UserChallenges}
                </ChallengeContainer>
            }
        </>
    )
}

export default AllChallengesContainer
