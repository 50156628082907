import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { useState, useEffect } from "react";
import Routes from "./Routes";
import axios from 'axios';
import appsettings from './appsettings';
import { AppContext } from './libs/contextLib';
import AppNav from './AppNav'

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const storedJwt = localStorage.getItem("token");
  const storedUserInfo = localStorage.getItem("userInfo");
  const [jwt, setJwt] = useState(storedJwt || null);
  const [userInfo, setUserInfo] = useState(storedUserInfo || { "username": null, "initial": "BK", "premium": false });

  useEffect(() => {
    const checkJwtStatus = () => {
      if (jwt) {
        // check the expiry of the token
        let expiry = new Date(localStorage.getItem("tokenexpires"))
        if (expiry > new Date())
          userHasAuthenticated(true);
      }
    };
    checkJwtStatus();
  }, []);

  useEffect(() => {
    async function getUserInitial() {
      if (isAuthenticated) {
        try {
          const { data } = await axios.get(`${appsettings.apiUrl()}/api/userInfo`);
          setUserInfo(data);
        } catch (e) {
          console.log(e);
        }
      }
    };
    getUserInitial();
  }, [isAuthenticated]);

  const handleLogout = () => {
    localStorage.clear();
    userHasAuthenticated(false);
    window.location.reload();
  }

  return (
    <div className="App">

      <AppNav isAuthenticated={isAuthenticated} handleLogout={handleLogout}></AppNav>

      <div className="nav-body-split"></div>

      <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, handleLogout, userInfo }}>
        <Routes />
      </AppContext.Provider>

    </div>
  );
}

export default App;
