import React, { useState } from "react";
import { FormGroup, FormControl,  Col, Container, Row } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import { useAppContext } from "../libs/contextLib";
import axios from 'axios';
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import appsettings from "../appsettings";
import { Link } from "react-router-dom";

export default function Login() {
    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        username: "",
        email: "",
        password: ""
    });

    function validateForm() {
        return fields.username.length > 0 && fields.password.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const { data } = await axios.post(`${appsettings.apiUrl()}/api/Authenticate/login`, {
                username: fields.username,
                password: fields.password
            });
            // console.log(data);
            localStorage.setItem('token', data.token);
            localStorage.setItem('tokenexpires', data.expiration);
            userHasAuthenticated(true);

        } catch (e) {
            setIsLoading(false);
            console.log(e);
            onError(e);
        }
    }

    return (
        <div>
            <div className="page-header dark-bg">
                <h1>LOGIN</h1>
            </div>
            <form onSubmit={handleSubmit} className="container">
                <FormGroup controlId="username" >
                    <label>Username</label>
                    <FormControl
                        autoFocus
                        type="text"
                        value={fields.username}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <FormGroup controlId="password" >
                    <label>Password</label>
                    <FormControl
                        value={fields.password}
                        onChange={handleFieldChange}
                        type="password"
                    />
                </FormGroup>
                <LoaderButton className="button-links center"
                    block
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateForm()}>
                    Login
                </LoaderButton>


            </form>

            <Container>
                <Row className="mt-5">
                    <Col>
                        <Link to="/forgot-password" className="button-links">Forgot password? </Link>
                    </Col>
                </Row>
            </Container>


            <div className="dark-bg mt-5">
                <div className="white-line-divider"></div>
                <Container>
                    <h2 className="negative-mt">OR</h2>
                    <Row className="mt-5 pb-5">
                        <Col>
                            <Link to="/signup" className="button-links center" >Register</Link>
                        </Col>
                    </Row>
                </Container>

            </div>

        </div>
    );
}