import React from 'react'
import "./ChallengeContainer.css"

function ChallengeContainer({ children }) {
    return (

        <div className="bottom pt-0" >
            <div className="container text-center">
                <h2 className="pagetitle">All Challenges</h2>
                <h3 className="">how do you measure up?</h3>

            </div>
            <div className="container ChallengeBadge__container">
                {children}
            </div>
        </div>
    )
}

export default ChallengeContainer;