import axios from 'axios'
import { useEffect, useState } from 'react'
import { Container, Row, Col, FormGroup, FormControl, Accordion, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import appsettings from '../appsettings'
import FixtureCard from '../components/FixtureCard'
import LoaderButton from '../components/LoaderButton'
import Loading from '../components/Loading'
import { onError } from '../libs/errorLib'
import './JoinGame.css'

const JoinGame = ({ }) => {
    const history = useHistory();
    const [code, setCode] = useState('')
    const [games, setGames] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function getGames() {
            try {
                const { data } = await axios.get(
                    `${appsettings.apiUrl()}/api/publicGames/`
                );
                console.log(data);
                setGames(data);
                setIsLoading(false);
            } catch (e) {
                onError(e.response);
                setIsLoading(false);
            }
        }
        getGames();
    }, [])

    async function joinPublicKitty(publiccode) {
        setCode(publiccode);
        handleSubmitRaw(publiccode);
    }
    async function handleSubmit(event) {
        event.preventDefault();
        handleSubmitRaw(code)
    }
    async function handleSubmitRaw(shareCode) {
        setIsLoading(true);
        try {
            const { data } = await axios.post(`${appsettings.apiUrl()}/api/joinfootykitty`, {
                code: shareCode,
            });
            console.log(data);
            setIsLoading(false);
            history.push("/")
        } catch (e) {
            setIsLoading(false);
            onError(e.response);
        }
    }

    function validateForm() {
        return code.length === 5
    }


    if (isLoading) {
        return <Loading></Loading>
    }
    return (

        <div>
            <div className="page-header dark-bg">
                <h1>Join Game</h1>
                <p>Use a code you have been sent by one of your mates to join their league – enter your code here</p>
            </div>

            <form onSubmit={handleSubmit} className="container mt-5 mb-5">
                <FormGroup controlId="username">
                    <label>enter code to join league</label>
                    <FormControl
                        autoFocus
                        type="text"
                        value={code}
                        onChange={(e) => { setCode(e.target.value) }}
                    />
                </FormGroup>
                <LoaderButton className="button-links center"
                    block
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateForm()}>
                    Join Game
                </LoaderButton>


            </form>


            <div className="dark-bg mt-5">
                <div className="white-line-divider"></div>
                <Container>
                    <h2 className="negative-mt">OR</h2>
                    <Row className="mt-5 pb-5">
                        <Col>
                            <p>Join one of public leagues and test yourself against other Bet Kitty players</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="container">
                {
                    games.length > 0 ?
                        <>
                            <Accordion defaultActiveKey="-1">

                                {games.map((g, key) => (
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey={`${key}`} className="bg-pink">
                                            <strong>{g.name}</strong>
                                            <span> Hosted by</span> <span>{g.host}</span>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={`${key}`}>
                                            <Card.Body>
                                                <p className="underlined">Games Included</p>

                                                {g.fixtures.map((item, keyx) => (
                                                    <FixtureCard key={keyx}
                                                        awayname={item.awayName}
                                                        // awayid={item.awayId}
                                                        competition={item.competition}
                                                        date={item.date}
                                                        time={item.time.hours}
                                                        homename={item.homeName}
                                                    // homeid={item.homeId}
                                                    // location={item.location}
                                                    ></FixtureCard>

                                                ))}

                                                <LoaderButton className="button-links center"
                                                    block
                                                    type="submit"
                                                    isLoading={isLoading}
                                                    onClick={() => { joinPublicKitty(g.code); }} >
                                                    Join Game
                                                </LoaderButton>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))}

                            </Accordion>

                        </>
                        :
                        <><h1>No Games Available</h1></>
                }

            </div>

        </div>

    )
}

export default JoinGame;