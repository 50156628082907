import axios from "axios";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import appsettings from "../appsettings";
import Loading from "../components/Loading";
import { onError } from "../libs/errorLib";
import "./ViewLeague.css";

const ViewLeague = ({}) => {
  const { code } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `${appsettings.apiUrl()}/api/FootyLeague/${code}`
        );
        console.log(data);
        setData(data);
        setIsLoading(false);
      } catch (e) {
        onError(e.response);
        setIsLoading(false);
      }
    }
    getData();
  }, []);

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <>
      <div className="page-header dark-bg">
        <h1 className="small-h1">League Positions</h1>
        <h2>{data.name}</h2>
        hosted by {data.host}
      </div>

      <Container>
        <Row>
          <Col>Name</Col>
          <Col>Points</Col>
          {/* <Col>Pos</Col> */}
        </Row>

        {data.members.map((item, key) => (
          <Row key={key} className="league-row">
            <Col>
              <Link to={`/playerprofile/${item.username}`}>
                {item.username}
              </Link>
            </Col>

            <Col>{item.points}</Col>
            {/* <Col>{item.points}</Col> */}
          </Row>
        ))}
      </Container>

      <Container className="mt-5 dark-bg pt-5 pb-5">
        <Link to="/mygames" className="button-links btn-primary btn btn-block">
          Back to Live Games
        </Link>
      </Container>
    </>
  );
};

export default ViewLeague;
