import React, { useState } from "react";
import { FormGroup, FormControl, label } from "react-bootstrap";
import { useFormFields } from "../libs/hooksLib";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import axios from "axios";
import appsettings from "../appsettings";
import "./StartKitty.css";
import { useHistory } from "react-router-dom";



export default function StartKitty() {
    const history = useHistory();
    const [fields, handleFieldChange] = useFormFields({
        name: "",
        public: true,
        fromdate: "",
        todate: ""
    });
    const [isLoading, setIsLoading] = useState(false);


    let updateWithCheckbox = (e) => {
        let x = {
            target: {
                id: e.target.id,
                value: e.target.checked
            }
        };
        handleFieldChange(x);
    }


    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            const apiresult = await axios.post(`${appsettings.apiUrl()}/api/footykitties`, {
                Name: fields.name,
                public: fields.public,
                fromdate: fields.fromdate,
                todate: fields.todate
            });
            setIsLoading(false);

            // push user to summary page?
            history.push("/startkitty-selectfixtures/" + apiresult.data.id);

        } catch (e) {
            onError(e.response);
            setIsLoading(false);
        }
    }

    function validateForm() {
        return (
            fields.name.length > 0  
        );
    }


    return (
        <div >
            <div className="page-header dark-bg">
                <h1>Create Your Own Game</h1>
            </div>

            <form onSubmit={handleSubmit} className="container">
                <br />
                <FormGroup controlId="name" >
                    <label>Name Your Game</label>
                    <FormControl
                        value={fields.name}
                        onChange={handleFieldChange}
                        placeholder="Kitty Name"
                    />
                </FormGroup>

                <FormGroup  className="d-flex text-center justify-content-center">
                    <label className="pr-2 pt-2">Make Public?</label>
                    <FormControl
                        type="checkbox"
                        className="splittoggle"
                        id="public"
                        onClick={updateWithCheckbox}
                        checked = {fields.public === true}
                    />
                </FormGroup>



                <LoaderButton
                    block
                    type="submit"
                    className="darkcolor button-links center"
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Next
                </LoaderButton>
            </form>
        </div>
    );
}