import React, { useState } from "react";
import { FormGroup, FormControl, Container, Row, Col } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import axios from 'axios';
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import appsettings from "../appsettings";
import { Link, useHistory } from "react-router-dom";

export default function SetNewPassword() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        code: "",
        password1: "",
        password2: "",
    });

    function validateForm() {
        return fields.code.length > 0 && fields.password1.length > 0 && fields.password1 === fields.password2;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const { data } = await axios.post(`${appsettings.apiUrl()}/api/Authenticate/SetPassword`, {
                code: fields.code,
                password: fields.password1,
                password2: fields.password2
            });
            console.log(data);
            alert("Your password has been reset, now login");
            history.push("/login")


        } catch (e) {
            setIsLoading(false);
            console.log(e);
            onError(e);
        }
    }

    return (
        <div className="">
            <div className="page-header">
                <h1>Password Reset</h1>
            </div>
            <form onSubmit={handleSubmit} className="container">
                <p>We've emailed you a code.  Use it below to set a new password.</p>
                <FormGroup controlId="code" >
                    <label>Code</label>
                    <FormControl
                        autoFocus
                        type="text"
                        value={fields.username}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <FormGroup controlId="password1" >
                    <label>New Password</label>
                    <FormControl
                        value={fields.password1}
                        onChange={handleFieldChange}
                        type="password"
                    />
                </FormGroup>
                <FormGroup controlId="password2" >
                    <label>New Password (again!)</label>
                    <FormControl
                        value={fields.password2}
                        onChange={handleFieldChange}
                        type="password"
                    />
                </FormGroup>
                <LoaderButton className="button-links"
                    block
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateForm()}>
                    Reset
                </LoaderButton>

            </form>

            <div className="dark-bg mt-5">
                <div className="white-line-divider"></div>
                <Container>
                    <h2 className="negative-mt">OR</h2>
                    <Row className="mt-5 pb-5">
                        <Col>
                            <Link to="/login" className="button-links " >Go Back</Link>
                        </Col>
                    </Row>
                </Container>

            </div>

        </div>
    );
}