import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import logo from "./images/logo_lightblue.png";
const brandlogo = <img src={logo} width="80" alt="Brand Logo" />;

const AppNav = ({ isAuthenticated, handleLogout }) => {
  return (
    <Navbar bg="light" variant="light" collapseOnSelect expand="lg">
      <Navbar.Brand href="/">{brandlogo}</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/" eventKey="0">
            Home
          </Nav.Link>

          {isAuthenticated ? (
            <>
              <Nav.Link as={Link} to="#" onClick={handleLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} className="spinning" />{" "}
                Logout
              </Nav.Link>
            </>
          ) : (
            <>
              <Nav.Link as={Link} to="/login" eventKey="10">
                Login
              </Nav.Link>
              <Nav.Link as={Link} to="/signup" eventKey="11">
                Register
              </Nav.Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNav;
