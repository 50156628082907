import React from "react";
import "./Home.css";
import BadgeAwardSwal from "../components/BadgeAwardSwal";
import MakeYourSelectionsSwal from "../components/MakeYourSelectionsSwal";
import MenuOption from "../components/MenuOption";

export default function Home() {
  // const { handleLogout, isAuthenticated, userInfo, colour } = useAppContext();
  // const [isLoading, setIsLoading] = useState(false);
  // const [myData, setMyData] = useState();

  // if (isLoading) {
  //   return <Loading></Loading>
  // }

  return (
    <>
      <BadgeAwardSwal></BadgeAwardSwal>
      <MakeYourSelectionsSwal></MakeYourSelectionsSwal>

      <MenuOption
        title="Create"
        tileText="Setup a Game"
        href="create-game"
        colour="purple"
      ></MenuOption>

      <div className="dark-bg">
        <MenuOption
          title="Join"
          tileText="Join a Game"
          href="join-game"
          // colour="pink"
        ></MenuOption>
      </div>

      <MenuOption
        title="Play"
        tileText="Games In Play"
        href="mygames"
        colour="pink"
      ></MenuOption>

      <div className="dark-bg">
        <MenuOption
          title="Me"
          tileText="View Profile"
          href="playerprofile"
          // colour="pink"
        ></MenuOption>
      </div>



      <MenuOption
        title="Goals"
        tileText="View Challenges"
        href="challenges"
        colour="purple"
      ></MenuOption>

      <div className="dark-bg">
        <MenuOption
          title="Rank"
          tileText="Leaderboard"
          href="leaderboard"
        ></MenuOption>
      </div>

      <MenuOption
        title="Help"
        tileText="How to play guide"
        href="howtoplay"
        colour="purple"
      ></MenuOption>
    </>
  );
}
