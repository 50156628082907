import React from 'react'
import './Badge.css'

function Badge({ title = "", complete = false, icon }) {


    return (
        <div className={`ChallengeBadge ${complete ? "complete" : "incomplete"}`}>
            <div className="ChallengeBadge__img">
                {icon}
            </div>
            <div className="ChallengeBadge__meta">
                <p><strong>{title}</strong></p>
            </div>
        </div>
    )
}

export default Badge;