import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { onError } from "../libs/errorLib";
import axios from "axios";
import appsettings from "../appsettings";
import "./StartKitty.css";
import { Link, useHistory, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import whatsapp from "../images/whatsapp.png";
import FixtureCard from "../components/FixtureCard";


export default function StartKittyConfirmed() {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [kitty, setKitty] = useState({});

    useEffect(() => {
        async function getSummary() {
            try {
                const { data } = await axios.get(
                    `${appsettings.apiUrl()}/api/FootyKitties/${id}`
                );
                console.log(data);
                setKitty(data);
                setIsLoading(false);
            } catch (e) {
                onError(e.response);
                setIsLoading(false);
            }
        }
        getSummary();

    }, [])


    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <div>
            <div className="page-header dark-bg">
                <h1>Game Set</h1>
                <p>Your game is ready, check the details below and share with your mates to get playing</p>

                <a href={`whatsapp://send?text=Join our kitty!  Use code: ${kitty.id} https://betkitty.co.uk/joinkitty/${kitty.code}`} data-action="share/whatsapp/share">
                    <div className="wa">
                        Share <img alt="whatsapp" src={whatsapp} />
                    </div>
                </a>
            </div>

            <Container className="mt-5 mb-5">
                <Row>
                    <Col xs={4} className="summary-text">Share Code</Col>
                    <Col xs={8} className="summary-value">{kitty.code}</Col>
                </Row>
                <hr />
                <Row>
                    {
                        kitty.name &&
                        <Col xs={4} className="summary-text">Name</Col>
                    }
                    {
                        kitty.name &&
                        <Col xs={8} className="summary-value">{kitty.name}</Col>
                    }
                </Row>
                <hr />
                <Row>
                    <Col xs={4} className="summary-text">Fixture dates</Col>
                    <Col xs={8} className="summary-value">
                        {new Date(kitty.kittyStartDate).toDateString()}
                        <br />-<br />
                        {new Date(kitty.kittyEndDate).toDateString()}

                    </Col>
                </Row>

                <hr />

                <Row>

                    {kitty.fixtures.map((item, keyx) => (
                        <FixtureCard key={keyx}
                            awayname={item.fixture.awayName}
                            onclick={() => { }}
                            competition={item.fixture.competition}
                            location={item.fixture.location}
                            date={item.fixture.date}
                            time={`${item.fixture.time.hours}:${item.fixture.time.minutes === 0 ? "00" : item.fixture.time.minutes}`}
                            homename={item.fixture.homeName}
                            homePredicted={item.fixture.homePredicted}
                            awayPredicted={item.fixture.awayPredicted}
                            homeActual={item.fixture.homeActual}
                            awayActual={item.fixture.awayActual}

                        // homeid={item.homeId}
                        // location={item.location}
                        ></FixtureCard>

                    ))}
                </Row>
            </Container>

            <div className="dark-bg">
                <Container className="pt-2 ">
                    <Row className="">
                        <Col>
                            <Link to="/" className="btn btn-block btn-primary button-links sticky-bottom">Done</Link>
                        </Col>
                    </Row>
                </Container>
                <Container className="pt-2 pb-5">
                    <Row className="">
                        <Col>
                            <Link to={`/startkitty-selectfixtures/${id}`} className="btn btn-block btn-warning button-links sticky-bottom">Change It</Link>
                        </Col>
                    </Row>
                </Container>
            </div>



        </div>
    );
}