import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import swal from '@sweetalert/with-react'
import appsettings from "../appsettings";
import Loading from "../components/Loading";
import SelectionsCard from "../components/SelectionsCard";
import { onError } from "../libs/errorLib";

const MakeSelections = ({ }) => {
    const history = useHistory();
    const { code } = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [dataReady, setDataReady] = useState(false)
    const [data, setData] = useState({})
    const [selections, setSelections] = useState([])
    const [counter, setCounter] = useState(0)

    useEffect(() => {
        async function getData() {
            try {
                const { data } = await axios.get(
                    `${appsettings.apiUrl()}/api/makeselections/${code}`
                );
                console.log(data);
                setData(data);
                setIsLoading(false);
                setDataReady(true);
            } catch (e) {
                onError(e.response);
                setIsLoading(false);
            }
        }
        getData();
    }, [])

    useEffect(() => {
        async function handleSubmit() {
            setIsLoading(true);

            try {
                console.log(selections)
                const apiresult = await axios.post(`${appsettings.apiUrl()}/api/makeselections`, {
                    code: code,
                    scores: selections,
                });
                setIsLoading(false);

                swal(
                    <div>
                        <h1 className="swal-h1">Predictions Set!</h1>
                    </div>
                ).then((e) => { history.push("/"); })

            } catch (e) {
                onError(e.response);
                setIsLoading(false);
            }
        }
        if (dataReady) {
            if (selections.length < data.fixtures.length) {
                setIsLoading(false);
            }
            else {
                handleSubmit();
            }
        }
    }, [selections])



    function handleSubmission(home, away, fixture) {
        // store the data 
        setSelections(selections => [...selections, { "home": home, "away": away, "fixture": fixture }])
        if (counter + 1 < data.fixtures.length) {
            setCounter(counter + 1)
        }
    }



    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <>
            <div >
                <div className="page-header dark-bg">
                    <h1 className="small-h1">Make Your Predictions Below</h1>
                </div>
            </div>

            <SelectionsCard
                fixture={data.fixtures[counter].fixtureId}
                homename={data.fixtures[counter].homeName}
                awayname={data.fixtures[counter].awayName}
                submitAction={handleSubmission}
            ></SelectionsCard>


        </>
    )
}

export default MakeSelections