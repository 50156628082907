import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./FixtureCard.css";

const FixtureCard = ({
  id,
  selected,
  onclick,
  homename,
  homeid,
  awayname,
  awayid,
  location,
  date,
  time,
  homePredicted = -1,
  homeActual = -1,
  awayPredicted = -1,
  awayActual = -1,
}) => {
  let colSize = homeid === undefined ? 5 : 3;

  return (
    <Container
      className={`FixtureCard pt-3 pb-3 ${selected ? "selected" : ""}`}
    >
      <Row
        onClick={() => {
          onclick();
        }}
      >
        {homeid && <Col xs={2}>[flag]</Col>}
        <Col xs={colSize}>{homename}</Col>
        <Col xs={2} className="vs">
          <span>VS</span>
        </Col>
        <Col xs={colSize}>{awayname}</Col>
        {awayid && <Col xs={2}>[flag]</Col>}
        <Col xs={12} className="when">
          {new Date(date).toDateString()} at {time}
        </Col>

        {homePredicted > -1 && awayPredicted > -1 ? (
          <>
            <Col xs={4} className="predicted-score purple">
              {homePredicted}
            </Col>
            <Col xs={4} className="predicted-text">
              Predicted
            </Col>
            <Col xs={4} className="predicted-score purple">
              {awayPredicted}
            </Col>
          </>
        ) : (
          <></>
        )}

        {homeActual > -1 && awayActual > -1 ? (
          <>
            <Col xs={4} className="actual-score pink">
              {homeActual}
            </Col>
            <Col xs={4} className="actual-text">
              Actual
            </Col>
            <Col xs={4} className="actual-score pink">
              {awayActual}
            </Col>
          </>
        ) : (
          <>
            {homePredicted > -1 && awayPredicted > -1 ? (
              <Link to={`/change-prediction/${id}`} className="btn btn-info mt-2 change-predictions">
                Change Predictions
              </Link>
            ) : (
              <></>
            )}
          </>
        )}

        <Col xs={12} className="where">
          {location}
        </Col>

        <div className="line-divider mt-1"></div>
      </Row>
    </Container>
  );
};

export default FixtureCard;
