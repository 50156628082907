import Axios from "axios";
import Slider from "react-slick";
import "./PlayerProfile.css"
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import appsettings from "../appsettings";
import { onError } from "../libs/errorLib";
import { Link } from "react-router-dom";
import { badgeForRef } from "../components/BadgeAwardAssets/BadgeForRef";
import sliderSettings from "./HomeSliderSettings";
import Badge from "../components/BadgeAwardAssets/Badge";
import { Col, Container, Row } from "react-bootstrap";

export default function PlayerProfile() {
    const { user } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [profile, setProfile] = useState({});
    const [mates, setMate] = useState(false);

    useState(() => {
        async function getData() {
            try {
                const { data } = await Axios.get(
                    `${appsettings.apiUrl()}/api/FootyPlayerProfile/${user}`
                );
                console.log(data);
                setProfile(data);
                setIsLoading(false);                    
                  
            } catch (e) {
                onError(e.response);
                setIsLoading(false);
            }
        }

        getData();
    }, []);
    useState(() => {
        async function checkMates() {
            try {
                const { data } = await Axios.get(
                    `${appsettings.apiUrl()}/api/mates/${user}`
                );
                console.log(data);
                setMate(data);
            } catch (e) {
                onError(e.response);
            }
        }

        checkMates();
    }, []);

    async function toggleMate(){
        try {
            let payload = {
                mateswith: user
            }
            const { data } = await Axios.post(
                `${appsettings.apiUrl()}/api/mates/`, payload
            );
            console.log(data);
            setMate(data);
        } catch (e) {
            onError(e.response);
        }
    }

    if (isLoading) {
        return "loading...";
    }

    return (
        <div>

            <div className="page-header primarybg">
                <h1 className="small-h1">{profile.username}'s Profile</h1>
                {mates ?

                    <button className="btn btn-primary" onClick={() => {toggleMate()}}>mates ✔️</button>
                    :
                    <button className="btn btn-info" onClick={() => {toggleMate()}}>add mate</button>
                }

            </div>

            <Container className="pt-4 pb-4">
                <Row>
                    <Col xs={12}><h1 className="small-h1">Kitty Score</h1></Col>
                    <Col xs={12}><span className="stats-value">{profile.kittyScore}</span></Col>
                </Row>
                <div className="divider"></div>
                <Row className="stats-breakdown mt-3">
                    <Col xs={6}>
                        <div>
                            <h1 className="pagetitle">Games Played</h1>
                            <span className="stats-value">{profile.kittiesPlayed}</span>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div>
                            <h1 className="pagetitle">Total Points</h1>
                            <span className="stats-value">{profile.totalPoints}</span>
                        </div>
                    </Col>
                </Row>
            </Container>



            <Container className="primarybg pt-4 pb-4">
                <Row>
                    <Col xs={12}><h1 className="small-h1">Recent Placings</h1></Col>
                    <Col xs={12}>
                        {profile.recentPlacing &&
                            <Link to={`/view-league/${profile.recentPlacing.code}`} className="">
                                <div className="recent-placing">
                                    <h1 className="smallpagetitle">{profile.recentPlacing.name}</h1>
                                    <h2>{profile.recentPlacing.datePretty} @ {profile.recentPlacing.course}</h2>
                                    <div className="recent-placing-position">
                                        {profile.recentPlacing.placePretty}
                                    </div>
                                </div>
                            </Link>
                        }

                        {
                            profile.recentPlacing === null ? <>No qualifying games played yet!</> : ""
                        }
                    </Col>
                </Row>
            </Container>


            <Container className=" pt-4 pb-4">
                <Row>
                    <Col xs={12}><h1 className="small-h1">Achievements</h1></Col>
                    <Col xs={12}>

                        <Slider {...sliderSettings}>

                            {
                                profile.badges.length === 0 ? <>Awkward, Nothing yet</> : ""
                            }


                            {
                                profile.badges &&
                                profile.badges.map(badge => (
                                    <div className="pl-5 pr-5">
                                        <Badge
                                            title={badge.displayName}
                                            key={badge.ref}
                                            complete={badge.complete}
                                            icon={badgeForRef(badge.ref)}
                                        />
                                    </div>
                                )
                                )
                            }

                        </Slider>
                    </Col>

                </Row>
            </Container>


        </div>
    );
}
