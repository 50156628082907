import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Container, Row, Col,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import axios from "axios";
import { onError } from "../libs/errorLib";
import "./Signup.css";
import appsettings from "../appsettings";

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (
      fields.username.length > 0 &&
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length >= 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const newUser = await axios.post(
        `${appsettings.apiUrl()}/api/Authenticate/register`,
        {
          username: fields.username,
          email: fields.email,
          password: fields.password,
          name: fields.name,
          dob: fields.dob,
          postcode: fields.postcode,
        }
      );
      console.log(newUser);
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      onError(e.response.data.message);
      setIsLoading(false);
    }
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const { data } = await axios.post(
        `${appsettings.apiUrl()}/api/Authenticate/login`,
        {
          username: fields.username,
          password: fields.password,
        }
      );
      localStorage.setItem("token", data.token);
      userHasAuthenticated(true);
      history.push("/");
    } catch (e) {
      setIsLoading(false);
      onError(e);
    }
  }

  function renderConfirmationForm() {
    return (
      <div className="">

        <div className="page-header">
          <h1>Thank you for joining!</h1>
        </div>

        <form onSubmit={handleConfirmationSubmit}>

          <LoaderButton
            className="button-links center"
            block
            type="submit"
            isLoading={isLoading}
            disabled={!validateConfirmationForm()}
          >
            Get started
          </LoaderButton>
        </form>
      </div>
    );
  }

  function renderForm() {
    return (
      <div className="">
        <div className="page-header dark-bg">
          <h1>Register</h1>
        </div>
        <form onSubmit={handleSubmit} className="container">

          <FormGroup controlId="email" >
            <label>Email</label>
            <FormControl
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup controlId="username" >
            <label>Username</label>
            <FormControl
              type="username"
              value={fields.username}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup controlId="password" >
            <label>Password</label>
            <FormControl
              type="password"
              value={fields.password}
              onChange={handleFieldChange}
            />
          </FormGroup>
          <FormGroup controlId="confirmPassword" >
            <label>Confirm Password</label>
            <FormControl
              type="password"
              onChange={handleFieldChange}
              value={fields.confirmPassword}
            />
          </FormGroup>
          <FormGroup controlId="name" >
            <label>Full Name</label>
            <FormControl
              type="text"
              onChange={handleFieldChange}
              value={fields.name}
            />
          </FormGroup>

          <div className="buttons">
            <LoaderButton
              block
              type="submit"
              className="button-links center"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              Signup
            </LoaderButton>

          </div>
        </form>



        <div className="dark-bg mt-5">
          <div className="white-line-divider"></div>
          <Container>
            <h2 className="negative-mt">OR</h2>
            <Row className="mt-5 pb-5">
              <Col>

                Already have an account? <br />
                <Link to="/login" className="button-links center" >Click here to login</Link>
              </Col>
            </Row>
          </Container>

        </div>




      </div>
    );
  }

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
}
