import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

export default function HowToPlay() {
  return (
    <div>
      <div className="page-header dark-bg">
        <h1 className="small-h1" className="small-h1">
          Bet Kitty Footy
        </h1>
        <p>
          Bet Kitty football lets you go head to head against your mates as you
          predict the scores across Europes top football leagues. Probably the
          best footy predictions game ever!
        </p>
      </div>

      <Container style={{ textAlign: "left" }}>
        <Row>
          <Col>
            <h1 className="small-h1">The Rules:</h1>
            <p>Points will be awarded in the following way:</p>
            <ul>
            <li>Correctly predict a match result (win/lose/draw) but not the correct score = 1pt</li>
            <li>Correctly predict a team’s total goals scored in the game = 2pts (points awarded per team, max 4pts)</li>
            <li>Total points available per match = 5pts</li>
            <li>All points are based on 90 minutes</li>
            <li>The league table will update at the end of that particular game week, once all scores are in.</li>
            </ul>
          

            <h1 className="small-h1">Hosting a game</h1>
            <ul className="rules">
              <li>Click Setup a Game from the home screen</li>
              <li>Choose a name for your league</li>
              <li>
                Decide if your league will be private (invite only) or public
              </li>
              <li>
                Select the games you want to include from the available games
                list
              </li>
              <li>
                Share using the WhatsApp button with your mates and invite them
                to join
              </li>
            </ul>
            <h1 className="small-h1">How to play:</h1>
            <ul>
              <li>
                {" "}
                Follow a link from your mates whatsapp message, or click Join
                Game from the home screen
              </li>
              <li>
                Enter your share code and click join game, OR join a public game
                listed beneath the share code section
              </li>
              <li>
                You will be alerted to Make your predictions! Click OK to be
                taken to the predictions screen
              </li>
              <li>
                Use the up/down arrows to set your predictions for the home and
                away teams
              </li>
              <li>
                Once done, your all set and ready for the game(s) to begin!
              </li>
            </ul>

            <Link
              className="LoaderButton button-links center btn btn-primary btn-block"
              to="/"
            >
              Home
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
