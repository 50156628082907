import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Button } from "react-bootstrap";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import "./SelectionsCard.css"
import LoaderButton from "./LoaderButton";

const SelectionsCard = ({ homename, awayname, key, submitAction, fixture, currentHomeScore = 0, currentAwayScore = 0, buttonText = "Next" }) => {

    const [homescore, setHome] = useState(currentHomeScore);
    const [awayscore, setAway] = useState(currentAwayScore);


    return (
        <>
            <Container className="SelectionsCard mb-4">
                <Row>
                    <Col xs={6} className="soccer">
                        {homename}
                    </Col>
                    <Col xs={3}>
                        {homescore}
                    </Col>
                    <Col xs={3}>
                        <Button onClick={() => { setHome(homescore + 1) }}><FontAwesomeIcon icon={faChevronUp} /></Button><br />
                        <Button disabled={homescore < 1} onClick={() => { setHome(homescore - 1) }}><FontAwesomeIcon icon={faChevronDown} /></Button>
                    </Col>
                </Row>
            </Container>

            <div className="white-line-divider"></div>
            <h2 className="negative-mt">VS</h2>

            <Container className="SelectionsCard">
                <Row>
                    <Col xs={6} className="soccer">
                        {awayname}
                    </Col>
                    <Col xs={3}>
                        {awayscore}
                    </Col>
                    <Col xs={3}>
                        <Button onClick={() => { setAway(awayscore + 1) }}><FontAwesomeIcon icon={faChevronUp} /></Button><br />
                        <Button disabled={awayscore < 1} onClick={() => { setAway(awayscore - 1) }}><FontAwesomeIcon icon={faChevronDown} /></Button>
                    </Col>
                </Row>
            </Container>

            <Container className="mt-4">
                <Row>
                    <Col>
                        <LoaderButton
                            block
                            type="submit"
                            className="darkcolor button-links center"
                            onClick={() => { submitAction(homescore, awayscore, fixture); setAway(0); setHome(0); }}
                        >
                            {buttonText}
                        </LoaderButton>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default SelectionsCard;