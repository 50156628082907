import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Card, useAccordionToggle } from "react-bootstrap";
import AccordionContext from "react-bootstrap/AccordionContext";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";


function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header
      className="bg-pink fixture-card-header"
      onClick={decoratedOnClick}
    >
      {children}

      {
        isCurrentEventKey ?
          <FontAwesomeIcon icon={faChevronUp} className="spinning float-right" />
          :
          <FontAwesomeIcon icon={faChevronDown} className="spinning float-right " />
      }
    </Card.Header>
  );
}

export default ContextAwareToggle