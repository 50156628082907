import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import "./Leaderboard.css";
import { onError } from "../libs/errorLib";
import appsettings from "../appsettings";
import { Container, Row, Col } from "react-bootstrap";

export default function KittyMembers() {
  const [isLoading, setIsLoading] = useState(true);
  const [leaderboard, setLeaderboard] = useState({});
  const [viewMatesOnly, setMatesOnly] = useState(false)

  useEffect(() => {
    async function getData() {
      try {
        const { data } = await Axios.get(
          `${appsettings.apiUrl()}/api/FootyLeaderboard/?filter=${viewMatesOnly}`
        );
        console.log(data);
        setLeaderboard(data);
        setIsLoading(false);
      } catch (e) {
        onError(e.response);
        setIsLoading(false);
      }
    }

    getData();
  }, [viewMatesOnly]);

  if (isLoading) {
    return "loading...";
  }

  return (
    <>
      <div className="page-header dark-bg">
        <h1 className="small-h1">The Leaderboard</h1>
        <p>Where do you come?</p>
        <p>
          Scores are calculated by your total number of points divided by the number of games you've played.  The higher your score, the more consistent you are at picking winners!
        </p>

        {viewMatesOnly ?

          <button className="btn btn-primary" onClick={() => { setMatesOnly(false) }}>View Global</button>
          :
          <button className="btn btn-info" onClick={() => { setMatesOnly(true) }}>View Mates Only</button>
        }

      </div>

      <Container>
        <Row>
          <Col xs={6}>Name</Col>
          <Col xs={2}>Played</Col>
          <Col xs={4}>Score</Col>
          {/* <Col>Pos</Col> */}
        </Row>

        {leaderboard.slice(0, 50).map((item, i) => (
          <Row key={i} className="league-row">

            <Col xs={2} className="league-item ">
              <div className="profile"> <span className="hash-span">#</span>{i + 1}</div>
            </Col>

            <Col xs={5} className="league-item left">
              <Link to={`/PlayerProfile/${item.username}`}>
                <div><span className="username">{item.username}</span></div>
              </Link>
            </Col>

            <Col>{item.played}</Col>

            <Col>{item.score}</Col>

          </Row>
        ))}

      </Container>

      <Container className="mt-5 dark-bg pt-5 pb-5">
        <Link to="/" className="button-links btn-primary btn btn-block">Back to Dashboard</Link>


      </Container>
    </>
  );
}
