import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./MenuOption.css";

const MenuOption = ({ title, tileText, href, colour  }) => {
  return (
    <Container className={`menu-option bg-${colour}`}>
        <Row>
            <Col xs={5} className="mo-left-tile">
                {title}
            </Col>
            <Col xs={7} className={`mo-right-tile `}>
                <h1>
                    {tileText}
                </h1>

                {
                    href && <> <Link to={`/${href}`}>GO</Link> </>
                }
            </Col>
        </Row>
    </Container>

)
};

export default MenuOption;
