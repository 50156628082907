import React, { useState } from "react";
import { FormGroup, FormControl, Container, Row, Col } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import axios from 'axios';
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import appsettings from "../appsettings";
import { Link, useHistory } from "react-router-dom";

export default function ForgotPassword() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        username: "",
        email: "",
    });

    function validateForm() {
        return fields.username.length > 0 && fields.email.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const { data } = await axios.post(`${appsettings.apiUrl()}/api/Authenticate/ForgotPassword`, {
                username: fields.username,
                email: fields.email
            });
            console.log(data);

            history.push("/setpassword");


        } catch (e) {
            setIsLoading(false);
            console.log(e);
            onError(e);
        }
    }

    return (
        <div className="">
            <div className="page-header dark-bg">
                <h1>Forgot Your Password?</h1>
            </div>
            <form onSubmit={handleSubmit} className="container">
                <FormGroup controlId="username" >
                    <label>Username</label>
                    <FormControl
                        autoFocus
                        type="text"
                        value={fields.username}
                        onChange={handleFieldChange}
                    />
                </FormGroup>
                <FormGroup controlId="email" >
                    <label>Email</label>
                    <FormControl
                        value={fields.email}
                        onChange={handleFieldChange}
                        type="email"
                    />
                </FormGroup>
                <LoaderButton className="button-links"
                    block
                    type="submit"
                    isLoading={isLoading}
                    disabled={!validateForm()}>
                    Send Help!
                </LoaderButton>

                <hr />
                <div>
                </div>
            </form>

            <div className="dark-bg mt-5">
                <div className="white-line-divider"></div>
                <Container>
                    <h2 className="negative-mt">OR</h2>
                    <Row className="mt-5 pb-5">
                        <Col>
                            <Link to="/login" className="button-links " >Go Back</Link>
                        </Col>
                    </Row>
                </Container>

            </div>



        </div>
    );
}