import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router";
import swal from '@sweetalert/with-react'
import appsettings from '../appsettings';
import Loading from '../components/Loading';
import SelectionsCard from '../components/SelectionsCard';
import { onError } from '../libs/errorLib';

const ChangePrediction = ({ }) => {
    const history = useHistory();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        async function getData() {
            try {
                const { data } = await axios.get(
                    `${appsettings.apiUrl()}/api/ChangePrediction/${id}`
                );
                console.log(data);
                setData(data);
                setIsLoading(false);
            } catch (e) {
                onError(e.response);
                setIsLoading(false);
            }
        }
        getData();

    }, [])

    function handleSubmission(home, away, fixture) {
        // store the data 
        try {
            setIsLoading(true);
            axios.post(
                `${appsettings.apiUrl()}/api/ChangePrediction/`, { "home": home, "away": away, "id": fixture }
            ).then((response) => {
                console.log(response)
                swal(
                    <div>
                        <h1 className="swal-h1">Prediction Changed!</h1>
                    </div>
                ).then((e) => { history.push("/mygames"); })
            })

            // setIsLoading(false);
        } catch (e) {
            onError(e.response);
            setIsLoading(false);
        }
    }



    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <>

            <div className="page-header dark-bg">
                <h1 className="small-h1">Change Prediction</h1>
            </div>


            <SelectionsCard
                fixture={data.id}
                homename={data.homename}
                awayname={data.awayname}
                currentAwayScore={data.away}
                currentHomeScore={data.home}
                buttonText="Update"
                submitAction={handleSubmission}
            ></SelectionsCard>
        </>

    )
}

export default ChangePrediction