import axios from "axios"
import React, { useEffect, useState } from "react"
import { Container, Card, Accordion, Row, Col } from "react-bootstrap"
import appsettings from "../appsettings"
import FixtureCard from "../components/FixtureCard"
import LoaderButton from "../components/LoaderButton"
import Loading from "../components/Loading"
import ContextAwareToggle from "../components/ContextAwareToggle"
import { onError } from "../libs/errorLib"
import { useHistory } from "react-router"

const MyGames = ({ }) => {
    const [data, setData] = useState()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)

    const [isHistoric, setIsHistoric] = useState(false)

    useEffect(() => {
        async function getData() {
            try {
                setIsLoading(true);
                const { data } = await axios.get(
                    `${appsettings.apiUrl()}/api/mygames?historic=${isHistoric}`
                );
                console.log(data);
                setData(data);
                setIsLoading(false);
            } catch (e) {
                onError(e.response);
                setIsLoading(false);
            }
        }
        getData();
    }, [isHistoric])



    if (isLoading) {
        return <Loading></Loading>
    }
    return (
        <>
            <div className="page-header dark-bg">
                <h1>My Games</h1>
                <p>All your live games are listed below, your predictions can be changed up until 15mins before the first game kicks off</p>
            </div>


            <Container>
                <Row>
                    <Col>


                        <Accordion defaultActiveKey="-1">
                            {data.map((g, key) => (
                                <Card key={key}>
                                    <ContextAwareToggle eventKey={`${key}`} >
                                        <strong>{g.name}</strong>
                                        <span> Hosted by</span> <span>{g.host}</span>
                                    </ContextAwareToggle>
                                    <Accordion.Collapse eventKey={`${key}`}>
                                        <Card.Body>
                                            <p className="underlined">Games Included</p>

                                            {g.fixturesandscores.map((item, keyx) => (
                                                <div>
                                                    <FixtureCard key={keyx}
                                                        id={item.entryId}
                                                        awayname={item.awayName}
                                                        onclick={() => { }}
                                                        competition={item.competition}
                                                        location={item.location}
                                                        date={item.date}
                                                        time={`${item.time.hours}:${item.time.minutes === 0 ? "00" : item.time.minutes}`}
                                                        homename={item.homeName}
                                                        homePredicted={item.homePredicted}
                                                        awayPredicted={item.awayPredicted}
                                                        homeActual={item.homeActual}
                                                        awayActual={item.awayActual}

                                                    // homeid={item.homeId}
                                                    // location={item.location}
                                                    ></FixtureCard>

                                                    

                                                </div>

                                            ))}

                                            <LoaderButton className="button-links center"
                                                block
                                                type="submit"
                                                isLoading={isLoading}
                                                onClick={() => { history.push(`/view-league/${g.code}`); }} >
                                                View League
                                            </LoaderButton>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion >

                        {data.length === 0 ? <>No Games to Show!</> : <></>}


                        <div className="page-header dark-bg">
                            <Container>
                                {isHistoric ?
                                    <button onClick={() => { setIsHistoric(false) }} className="button-links btn-primary btn btn-block">Back to Live Games</button>
                                    :
                                    <button onClick={() => { setIsHistoric(true) }} className="button-links btn-primary btn btn-block">View Old Games</button>
                                }
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default MyGames