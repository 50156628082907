import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import TestSecureEndpoint from "./components/tests/TestSecureEndpoint";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ClearCache from "./components/tests/ClearCache";
import ForgotPassword from "./containers/ForgotPassword";
import SetNewPassword from "./containers/SetNewPassword";
import HowToPlay from "./containers/HowToPlay";
import Terms from "./containers/Terms";
import Privacy from "./containers/Privacy";
import Leaderboard from "./containers/Leaderboard"
import AllChallengesContainer from "./containers/AllChallengesContainer";
import PlayerProfile from "./containers/PlayerProfile";
import StartKitty from "./containers/StartKitty";
import StartKittySelectFixtures from "./containers/StartKittySelectFixtures";
import StartKittyConfirmed from "./containers/StartKittyConfirmed";
import JoinGame from "./containers/JoinGame";
import MakeSelections from "./containers/MakeSelections";
import MyGames from "./containers/MyGames";
import ViewLeague from "./containers/ViewLeague";
import ChangePrediction from "./containers/ChangePrediction";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/test">
        <TestSecureEndpoint />
      </Route>

      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>

      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>
      <Route exact path="/terms">
        <Terms />
      </Route>
      <Route exact path="/privacy">
        <Privacy />
      </Route>
      
      <UnauthenticatedRoute exact path="/forgot-password">
        <ForgotPassword />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/setpassword">
        <SetNewPassword />
      </UnauthenticatedRoute>

      <Route exact path="/howtoplay">
        <HowToPlay />
      </Route>

      <AuthenticatedRoute exact path="/leaderboard">
        <Leaderboard />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/challenges">
        <AllChallengesContainer />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/playerprofile/:user?">
        <PlayerProfile />
      </AuthenticatedRoute>

      
      <AuthenticatedRoute exact path="/create-game">
        <StartKitty />
      </AuthenticatedRoute>      
      <AuthenticatedRoute exact path="/startkitty-selectfixtures/:id">
        <StartKittySelectFixtures />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/startkitty-confirmed/:id">
        <StartKittyConfirmed />
      </AuthenticatedRoute>
      
      <AuthenticatedRoute exact path="/join-game">
        <JoinGame />
      </AuthenticatedRoute>   
      <AuthenticatedRoute exact path="/make-selections/:code">
        <MakeSelections />
      </AuthenticatedRoute>   
      
      <AuthenticatedRoute exact path="/mygames">
        <MyGames />
      </AuthenticatedRoute>   
      
      <AuthenticatedRoute exact path="/view-league/:code">
        <ViewLeague />
      </AuthenticatedRoute>   
       
      <AuthenticatedRoute exact path="/change-prediction/:id">
        <ChangePrediction />
      </AuthenticatedRoute>


      <Route exact path="/cc">
        <ClearCache />
      </Route>

      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
