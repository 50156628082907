
export default function ClearCache(){

    caches.keys().then(function(names) {
        for (let name of names)
            caches.delete(name);
    });

    window.location.href = "./";

    
    return (

        "cleared"

    );
}