import React, { useEffect, useState } from "react";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import axios from "axios";
import appsettings from "../appsettings";
import "./StartKitty.css";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../components/Loading"
import FixtureCard from "../components/FixtureCard";
import ContextAwareToggle from "../components/ContextAwareToggle"
import { Accordion, Card, Col, Container, Row } from "react-bootstrap";



export default function StartKittySelectFixtures() {
    const { id } = useParams();
    const history = useHistory();
    const [fixtures, setFixtures] = useState([])
    const [selections, setSelections] = useState([])

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        async function getUpcomingFixtures() {
            try {
                const { data } = await axios.get(
                    `${appsettings.apiUrl()}/api/upcomingfixtures`
                );
                console.log(data);
                setFixtures(data);
                setIsLoading(false);
            } catch (e) {
                onError(e.response);
                setIsLoading(false);
            }
        }

        getUpcomingFixtures();

    }, [])


    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            const apiresult = await axios.post(`${appsettings.apiUrl()}/api/FootyKittiesSetFixtures`, {
                kittyId: parseInt(id),
                fixtures: selections,
            });
            setIsLoading(false);

            // push user to summary page?
            history.push("/startkitty-confirmed/" + apiresult.data.id);

        } catch (e) {
            onError(e.response);
            setIsLoading(false);
        }
    }

    function manageSelections(id) {
        if (selections.includes(id)) {
            setSelections(selections.filter(i => i !== id));
        }
        else {
            setSelections(selections => [...selections, id]);
        }
        console.log(selections);
    }

    function highlight(id) {
        return selections.includes(id);
    }

    function validateForm() {
        return selections.length > 0
    }



    if (isLoading) {
        return <Loading></Loading>
    }

    return (
        <Container>
            <Row>
                <Col>
                    <div >
                        <div className="page-header dark-bg">
                            <h1>Select Your Fixtures</h1>
                        </div>



                        <Accordion defaultActiveKey="-1">
                            {fixtures.map((g, key) => (
                                <Card key={key}>
                                    <ContextAwareToggle eventKey={`${key}`} >
                                        <strong>{g.compName}</strong>
                                    </ContextAwareToggle>
                                    <Accordion.Collapse eventKey={`${key}`}>
                                        <Card.Body>
                                            <p className="underlined">Fixtures</p>

                                            {g.fixtures.map((item, keyx) => (
                                                <FixtureCard key={key}
                                                    awayname={item.awayName}
                                                    // awayid={item.awayId}
                                                    competition={item.competition}
                                                    date={item.date}
                                                    time={`${item.time.hours}:${item.time.minutes === 0 ? "00" : item.time.minutes}`}
                                                    homename={item.homeName}
                                                    // homeid={item.homeId}
                                                    // location={item.location}
                                                    onclick={() => { manageSelections(item.fixtureId) }}
                                                    selected={highlight(item.fixtureId)}
                                                ></FixtureCard>

                                            ))}

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion >



                        <LoaderButton
                            block
                            type="submit"
                            className="darkcolor button-links center sticky-bottom"
                            isLoading={isLoading}
                            disabled={!validateForm()}
                            onClick={(e) => { handleSubmit(e) }}
                        >
                            Next <small>({selections.length})</small>
                        </LoaderButton>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}